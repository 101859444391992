<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('admin.products.tabs.general.label')">
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group
                        :label="$t('form.title.label')"
                        :label-for="'title.' + language.code"
                      >
                        <b-form-input
                          :id="'title.' + language.code"
                          v-model="item.title[language.code]"
                          :state="errors && errors['title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                          {{ errors['title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group
                        :label="$t('form.description.label')"
                        :label-for="'description.' + language.code"
                        :state="errors && errors['description.' + language.code] ? false : null"
                      >
                        <b-form-input
                          :id="'description.' + language.code"
                          v-model="item.description[language.code]"
                          :state="errors && errors['description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                          {{ errors['description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        :label="$t('form.html1.label')"
                        :label-for="'html1.' + language.code"
                        :state="errors && errors['html1.' + language.code] ? false : null"
                      >
                        <b-form-textarea
                          :id="'html1.' + language.code"
                          v-model="item.html1[language.code]"
                          :state="errors && errors['html1.' + language.code] ? false : null"
                          style="min-height: 300px;"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['html1.' + language.code]">
                          {{ errors['html1.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="false"
                      cols="12"
                    >
                      <b-form-group
                        :label="$t('form.html2.label')"
                        :label-for="'html2.' + language.code"
                        :state="errors && errors['html2.' + language.code] ? false : null"
                      >
                        <b-form-textarea
                          :id="'html2.' + language.code"
                          v-model="item.html2[language.code]"
                          :state="errors && errors['html2.' + language.code] ? false : null"
                          style="min-height: 300px;"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['html2.' + language.code]">
                          {{ errors['html2.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.data.label')">
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.cities.label')"
                    label-for="cities"
                    :state="errors && errors.cities ? false : null"
                  >
                    <v-select
                      id="cities"
                      v-model="item.cities"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="uniqCities"
                      :reduce="val => val.value"
                      :clearable="true"
                      :multiple="true"
                      input-id="cities"
                      @search="filterCities"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.cities">
                      {{ errors.cities[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.price.label')"
                    label-for="price"
                  >
                    <b-form-input
                      id="price"
                      v-model="item.price"
                      :state="errors && errors.price ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.price">
                      {{ errors.price[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.freedelivery.label')"
                    label-for="free"
                  >
                    <b-form-input
                      id="free"
                      v-model="item.free"
                      :state="errors && errors.free ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.free">
                      {{ errors.free[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.key.label')"
                    label-for="key"
                  >
                    <v-select
                      v-model="item.key"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="deliveryTypes"
                      :reduce="val => val.value"
                      :clearable="false"
                      :state="errors && errors.key ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.key">
                      {{ errors.key[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.position.label')"
                    label-for="free"
                  >
                    <b-form-input
                      id="position"
                      v-model="item.position"
                      :state="errors && errors.position ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.position">
                      {{ errors.position[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group :label="$t('form.avatar.label')">
                    <b-media no-body>
                      <b-media-aside>
                        <b-link>
                          <b-img
                            ref="previewEl"
                            rounded
                            :src="$options.filters.mediaUrl(item, 'avatar', '150x150')"
                            height="80"
                          />
                        </b-link>
                        <!--/ avatar -->

                        <!-- reset -->
                        <b-button
                          v-if="item.avatar"
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="item.avatar = null"
                        >
                          {{ $t('general.reset') }}
                        </b-button>
                      <!--/ reset -->
                      </b-media-aside>

                      <b-media-body class="mt-75 ml-75">
                        <InitFileManager
                          field="avatar"
                          type="delivery_method_avatar"
                          :multiple="false"
                          @fm-selected="selectedAvatar"
                        />

                        <b-row
                          v-if="errors && errors.avatar"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors.avatar[0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>
                        <b-row
                          v-if="errors && errors['avatar.type']"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors['avatar.type'][0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>

                      </b-media-body>
                    </b-media>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'delivery_methods-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import EditorMixin from '@/mixins/EditorMixin'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  mixins: [GlobalMixin, EditorMixin],
  data() {
    return {
      item: null,
      languages: [],
      cityOptions: [],
    }
  },
  computed: {
    uniqCities() {
      return _.uniq(this.cityOptions, 'value')
    },
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    this.$http.get('/api/cities', { params: { base: '1' } })
      .then(response => {
        this.cityOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    await this.$http.get(`/api/admin/delivery_methods/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'delivery_methods-index' })
        }
      })
  },
  methods: {
    selectedAvatar(data) {
      this.item.avatar = data
    },
    onSubmit() {
      const data = {
        title: this.item.title,
        description: this.item.description,
        html1: this.item.html1,
        html2: this.item.html2,
        avatar: this.item.avatar,
        status: this.item.status,
        price: this.item.price,
        free: this.item.free,
        key: this.item.key,
        position: this.item.position,
        cities: this.item.cities,
      }

      this.$http.put(`/api/admin/delivery_methods/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'delivery_methods-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    transformData(item) {
      const data = {
        id: item.id,
        status: item.status,
        key: item.key,
        price: item.price,
        free: item.free,
        position: item.position,
        cities: [],
        title: {},
        description: {},
        html1: {},
        html2: {},
        avatar: this.$options.filters.singleMedia(item.avatar),
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
        data.description[language.code] = translation ? translation.description : null
        data.html1[language.code] = translation ? translation.html1 : null
        data.html2[language.code] = translation ? translation.html2 : null
      })

      data.cities = _.map(item.cities, 'city_id')

      _.each(item.cities, city => {
        this.cityOptions.push({
          value: city.city.id,
          label: city.city.title,
        })
      })

      return data
    },
    filterCities(search, loading) {
      if (search.length) {
        loading(true)
        this.searchCity(loading, search, this)
      }
    },
    searchCity: _.debounce((loading, search, vm) => {
      vm.$http.get(`/api/cities/?query=${search}`).then(response => {
        vm.cityOptions = _.uniqBy(_.concat(vm.cityOptions, vm.$options.filters.transformForVSelect(response.data.data, 'id', 'title')), 'value')
        loading(false)
      })
    }, 350),
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
